import fetcher from "~/js/api/fetcher";
import { COMMENTS_SAVE } from "~/js/constants/api-end-points";
import { isRtl } from "~/js/utils/helpers/isRtl";

/**
 *
 * @param {*} commentConfig
 * @returns promise with posted comment
 * @memberof Comments
 */
export function postComment(commentConfig) {
    // Send event to Analytics dataLayer when comment or reply is posted from an article
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "commentSent"
    });

    return fetcher(
        `/${isRtl ? "ar" : "en"}${COMMENTS_SAVE}`,
        "POST",
        commentConfig
    );
}

/**
 *
 * @returns promise with comments
 * @memberof Comments
 */
export function getComments(articleId, listLength, index) {
    const listCommentsUrl = `/api/v1/article/comment/${articleId}/list?listRequestModel.startIndex=${index}&listRequestModel.length=${listLength}`;

    return fetcher(`/${isRtl ? "ar" : "en"}${listCommentsUrl}`, "POST");
}

/**
 *
 *
 * @param {*} commentId
 * @returns promise with deleted comment
 * @memberof Comments
 */
export function deleteComment(commentId) {
    const deleteCommentUrl = `/api/v1/article/comment/${commentId}/delete`;

    return fetcher(`/${isRtl ? "ar" : "en"}${deleteCommentUrl}`, "POST");
}
